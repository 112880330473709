import React, { useEffect } from 'react'

import Image from 'next/image'

import { useModuleContext } from '@/contexts/ModuleContext'
import { BestMoneyLogo, ForbesLogo, ForbesUsaTodayLogo, Plus } from '@/icons'
import consumerLogoBlack from '@/img/ethos-logo-black.svg'
import consumerLogoForest from '@/img/ethos-logo-forest.svg'
import consumerLogoWhite from '@/img/ethos-logo-white.svg'
import { Button } from '@getethos/ethos-design-system-v2'
import classNames from 'classnames'
import { CloudinaryImage } from 'ethos-design-system'

import { LoginExperimentButton } from '@/components/Navigation/LoginCTAExperiment'
import { SingleCTAInterface } from '@/components/SingleCTA/interface'

import useCheckCobrandingNewPartnersLogos from '@/hooks/cobranded/useCheckCobrandingNewPartnersLogos'
import { useCobrandedUtmTerms } from '@/hooks/cobranded/useCobrandedTerms'
import { useForbesHeaderNavbar } from '@/hooks/cobranded/useForbesHeaderNavbar'
import { useForbesUsaTodayNavbar } from '@/hooks/cobranded/useForbesUsaTodayNavbar'
import { useBestMoneyHeader } from '@/hooks/features/useBestMoneyHeader'
import { useBrandColorsLandingPageExperiment } from '@/hooks/features/useBrandColorsLandingPageExperiment'
import useCobrandingNewPartners from '@/hooks/features/useCobrandingNewPartners'
import { useCobrandingOnMobile } from '@/hooks/features/useCobrandingOnMobile'
import { useHeadlineRapidTest } from '@/hooks/features/useHeadlineRapidTest'
import { useLoginCTAExperiment } from '@/hooks/features/useLoginCTAExperiment'
import { useLpGlobalWinner } from '@/hooks/features/useLpGlobalWinner'
import { useWreathsFooterUpdate } from '@/hooks/features/useWreathsFooterUpdate'
import useDetectScreenSize from '@/hooks/useDetectScreenSize'

import styles from './SingleCTADirect.module.scss'
import {
  TRUST_IMAGE_GROUPS,
  TRUST_IMAGE_GROUPS_7E7E7E,
  TRUST_IMAGE_GROUPS_FFFFFF,
  TRUST_IMAGE_WREATH_UPDATE_BLACK,
  TRUST_IMAGE_WREATH_UPDATE_WHITE,
} from './constants'

interface Props {
  ctaClick: (props: { flow?: string; label?: string }) => void
  moduleData: SingleCTAInterface
}

const SingleCTADirect = ({ ctaClick, moduleData }: Props) => {
  const { isLaptopAndUp } = useDetectScreenSize()

  const { isTreatment1 } = useCobrandingOnMobile()
  const { isTreatment1: newPartnersTreatment } = useCobrandingNewPartners()
  const { isForbesUtmTerm, isBestMoneyUtmTerm, isForbesUsaTodayTerm } =
    useCobrandedUtmTerms()

  let partnerLogoMobile: JSX.Element | null = null

  // --------- TODO: clean up after new partners treatment is over ---------
  const isForbesHeaderEnabled = useForbesHeaderNavbar(
    isTreatment1 || newPartnersTreatment
  )

  const isForbesUsaTodayHeaderEnabled = useForbesUsaTodayNavbar(
    isTreatment1 || newPartnersTreatment
  )
  const { isEnabled: isBestMoneyHeaderEnabled } = useBestMoneyHeader(
    isTreatment1 || newPartnersTreatment
  )

  if (isForbesUtmTerm || isForbesHeaderEnabled) {
    partnerLogoMobile = <ForbesLogo />
  } else if (isBestMoneyUtmTerm || isBestMoneyHeaderEnabled) {
    partnerLogoMobile = <BestMoneyLogo />
  } else if (isForbesUsaTodayTerm || isForbesUsaTodayHeaderEnabled) {
    partnerLogoMobile = <ForbesUsaTodayLogo />
  }
  // ------------------------------------------------------------------------

  partnerLogoMobile = useCheckCobrandingNewPartnersLogos(partnerLogoMobile)

  const {
    navBarControls: { hideNavBar },
  } = useModuleContext()

  const { isTreatment } = useWreathsFooterUpdate()

  useEffect(() => {
    hideNavBar(!isLaptopAndUp)
  }, [hideNavBar, isLaptopAndUp])

  const { headline } = useHeadlineRapidTest()
  const ctaLabel = moduleData.ctaLabel || 'Check my price'

  const { isVariant1, isVariant2, isVariant3 } =
    useBrandColorsLandingPageExperiment()

  const {
    variation: loginCTAExperimentVariation,
    isTreatment: isLoginCTAExperimentTreatment,
  } = useLoginCTAExperiment()

  const {
    isTreatment1: isLpGlobalWinnerTreatment1,
    isTreatment2: isLpGlobalWinnerTreatment2,
    isTreatment3: isLpGlobalWinnerTreatment3,
    isTreatment4: isLpGlobalWinnerTreatment4,
  } = useLpGlobalWinner()

  const isLpGlobalWinnerTreatment =
    isLpGlobalWinnerTreatment1 ||
    isLpGlobalWinnerTreatment2 ||
    isLpGlobalWinnerTreatment3 ||
    isLpGlobalWinnerTreatment4

  const trustworthyStyle =
    isLpGlobalWinnerTreatment1 ||
    isLpGlobalWinnerTreatment2 ||
    isLpGlobalWinnerTreatment4

  let trustImageGroups = TRUST_IMAGE_GROUPS

  const showWhiteImages = isVariant1 || isVariant2 || trustworthyStyle
  const consumerLogo = trustworthyStyle ? consumerLogoWhite : consumerLogoForest

  if (showWhiteImages) {
    trustImageGroups = TRUST_IMAGE_GROUPS_FFFFFF
  } else if (isVariant3) {
    trustImageGroups = TRUST_IMAGE_GROUPS_7E7E7E
  }

  if (isTreatment) {
    trustImageGroups = showWhiteImages
      ? TRUST_IMAGE_WREATH_UPDATE_WHITE
      : TRUST_IMAGE_WREATH_UPDATE_BLACK
  }

  const shouldShowLogoWithPartner = isTreatment1 || newPartnersTreatment

  let bgImage = (
    <>
      <div
        className={classNames(styles.bgImage, {
          [styles.lpGlobalWinnerTreatment2]: isLpGlobalWinnerTreatment2,
          [styles.lpGlobalWinnerTreatment3]: isLpGlobalWinnerTreatment3,
        })}
      >
        <Image
          priority
          src="https://res.cloudinary.com/getethos/image/upload/v1665678125/family_stock_photo_2_plqkwh.png"
          alt="Family"
          width={500}
          height={560}
          quality={100}
        />
      </div>
    </>
  )

  if (isLpGlobalWinnerTreatment1 || isLpGlobalWinnerTreatment4) {
    bgImage = (
      <div
        className={classNames(styles.bgImage, styles.huggingImage, {
          [styles.showBgImageMobile]:
            isLpGlobalWinnerTreatment1 || isLpGlobalWinnerTreatment4,
        })}
      >
        <img
          src="https://res.cloudinary.com/getethos/image/upload/v1743635480/family_hugging_mu2wca.png"
          alt="Father mother and child hugging"
        />
      </div>
    )
  }

  return (
    <section
      className={classNames(styles.wrapper, {
        [styles.variant1]: isVariant1,
        [styles.variant2]: isVariant2,
        [styles.variant3]: isVariant3,
        [styles.trustworthy]: trustworthyStyle,
        [styles.lpGlobalWinnerTreatment1]: isLpGlobalWinnerTreatment1,
        [styles.lpGlobalWinnerTreatment2]: isLpGlobalWinnerTreatment2,
        [styles.lpGlobalWinnerTreatment3]: isLpGlobalWinnerTreatment3,
        [styles.lpGlobalWinnerTreatment4]: isLpGlobalWinnerTreatment4,
      })}
    >
      <div
        className={classNames(styles.container, {
          [styles.trustworthy]: trustworthyStyle,
          [styles.showBgImageMobile]:
            isLpGlobalWinnerTreatment1 || isLpGlobalWinnerTreatment4,
        })}
      >
        <div className={styles.mainContent}>
          <div
            className={classNames(
              shouldShowLogoWithPartner ? styles.logoWithPartner : styles.logo,
              {
                'flex w-full flex-row items-center justify-between':
                  isLoginCTAExperimentTreatment,
              }
            )}
          >
            {shouldShowLogoWithPartner && partnerLogoMobile ? (
              <>
                <Image
                  src={consumerLogoBlack}
                  alt="Ethos Logo"
                  width={87}
                  height={16}
                  fetchPriority="high"
                />
                <Plus />
                <div className={styles.mobileLogo}>{partnerLogoMobile}</div>
              </>
            ) : (
              <img src={consumerLogo.src} alt="Ethos Logo" />
            )}

            {isLoginCTAExperimentTreatment && (
              <LoginExperimentButton
                isMobile
                treatment={loginCTAExperimentVariation}
              />
            )}
          </div>

          <h1
            className={classNames(styles.header, {
              [styles.variant1]: isVariant1,
              [styles.variant2]: isVariant2,
              [styles.variant3]: isVariant3,
              [styles.trustworthy]: trustworthyStyle,
              [styles.lpGlobalWinnerTreatment]: isLpGlobalWinnerTreatment,
            })}
          >
            {headline ? (
              headline
            ) : (
              <>
                Instant <br /> life insurance
              </>
            )}
          </h1>
          <div
            className={classNames(
              'flex items-center gap-2',
              isLpGlobalWinnerTreatment
                ? isLaptopAndUp
                  ? 'mb-10'
                  : 'mb-4'
                : 'mb-[42px]'
            )}
          >
            <p
              className={classNames(styles.subheader, {
                [styles.variant1]: isVariant1,
                [styles.variant2]: isVariant2,
                [styles.variant3]: isVariant3,
                [styles.trustworthy]: trustworthyStyle,
              })}
            >
              no medical exams
            </p>
            <div className="mb-1 h-[6px] w-[6px] rounded-full bg-[#82B0AB]"></div>
            <p
              className={classNames(styles.subheader, {
                [styles.variant1]: isVariant1,
                [styles.variant2]: isVariant2,
                [styles.variant3]: isVariant3,
                [styles.trustworthy]: trustworthyStyle,
              })}
            >
              no blood tests
            </p>
          </div>

          <Button
            className={classNames(styles.button, {
              [styles.variant1]: isVariant1,
              [styles.variant2]: isVariant2,
              [styles.variant3]: isVariant3,
            })}
            buttonTitle={ctaLabel}
            alignment="center"
            variant="salamander"
            type="submit"
            size="lg"
            onClick={() => ctaClick({ label: ctaLabel })}
          />
          <div
            className={classNames(styles.trustImages, {
              [styles.lpGlobalWinnerTreatment]: isLpGlobalWinnerTreatment,
            })}
          >
            {trustImageGroups.map((trustImageGroup, index) => (
              <div className={styles.trustImage} key={`trustImage-${index}`}>
                <CloudinaryImage
                  publicId={trustImageGroup.groupImage}
                  alt={trustImageGroup.imageAlt}
                  className={styles.trustImage}
                  crop={CloudinaryImage.CROP_METHODS.FIT}
                  height={[55, 55, 55, 55]}
                  width={[90, 90, 90, 90]}
                />
              </div>
            ))}
          </div>
        </div>

        <div
          className={classNames(styles.bgContainer, {
            [styles.showBgImageMobile]:
              isLpGlobalWinnerTreatment1 || isLpGlobalWinnerTreatment4,
          })}
        >
          {bgImage}
        </div>
      </div>
    </section>
  )
}

export default SingleCTADirect
